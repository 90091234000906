import {
  HomeBanner,
  HomeBannerApi as HomeBannerApiClient,
  ImageHomeBanner,
} from 'pleinchamp-api-client/dist/src/homeBanner/homeBanner';
import React, { ComponentPropsWithRef, FC, useEffect, useState } from 'react';
import { ApiConfiguration, axiosInstance } from '@api/business/api.conf';
import { usePlcApiCall } from '@api/business/api.hook';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcImg } from '@components/PlcImg/PlcImg.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';

import './HomeBanner.scss';

const HomeBannerApi = new HomeBannerApiClient(ApiConfiguration, undefined, axiosInstance);

/**
 * permet de faire l'appel réseau pour récupérer les HomesBanner
 * @returns  Les homeBanner
 */
async function fetchHomeBanner() {
  const { data } = await HomeBannerApi.homeBanner();
  return data as HomeBanner;
}

const HomeBannerComponent: FC<ComponentPropsWithRef<'div'>> = () => {
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const [image, setImage] = useState<ImageHomeBanner | undefined>();

  const { data: results } = usePlcApiCall(fetchHomeBanner, []);
  useEffect(() => {
    setImage(isMobile ? results?.mobileImage : results?.webImage);

    return () => {
      setImage(undefined);
    };
  }, [isMobile, results]);

  if (!results) {
    return null;
  }

  return (
    <div className="wrapper-s plc-mb-l plc-mb-up-m-xl">
      <BasicLink href={results?.redirectURL} isUnderlined target="_blank">
        <PlcImg
          alt={image?.alt || ''}
          loader={<PlcImgLoader height={200} uniqueKey="homeBanner" width={500} />}
          plcSrc={{ src: image?.url || '', type: 'external' }}
        />
      </BasicLink>
    </div>
  );
};

export { HomeBannerComponent };
